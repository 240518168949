import React, { useState, useEffect } from "react"
import { Nav, Modal } from "react-bootstrap"
import Scrollspy from 'react-scrollspy';
import $ from "jquery";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import getVideoId from 'get-video-id';
import CustomVideo from "../PlayVideo/custom-video"
import "./styles/_index.scss";

const links = [
  {
    text: 'Description',
    url: '#description',
    isSpy: true
  },
  {
    text: 'Video',
    url: '#video',
    isSpy: true
  },
  {
    text: 'Floorplan',
    url: '#floorplan',
  },
  {
    text: 'Gallery',
    url: '#gallery',
  },
  {
    text: 'Map',
    url: '#property-details-map',
    isSpy: true
  },
  {
    text: 'Calculators',
    url: '#calculators',
    isSpy: true
  },
];


const getOffset = el => {
  var _x = 0
  var _y = 0
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft
    _y += el.offsetTop - el.scrollTop
    el = el.offsetParent
  }
  return { top: _y, left: _x }
}

const PropertyNav = (props) => {
  const scrollHandle = (e) => {
    e.preventDefault();
    const section = e.target.getAttribute('href')?.slice(1);
    const element = document.querySelector(`[data-item="${section}"]`);
    const offestTop = getOffset(element).top;
    const stickyHeight = document.querySelector('.property-nav').clientHeight;
    window.scrollTo({
      top: offestTop - stickyHeight,
      behavior: 'smooth'
    });
  }

  // Click to scroll
  useEffect(() => {
    var propertySelector = '.property-nav__inner .nav .nav-item';

    $(propertySelector).on('click', function(){
        $(propertySelector).removeClass('is-current');
        $(this).addClass('is-current');
    });

  },[]);
  // Click to scroll

  // Video id
  const videoid = props?.propertyData && props?.propertyData?.virtual_tour && props?.propertyData?.virtual_tour.length > 0 && props?.propertyData?.virtual_tour[0] && getVideoId(props?.propertyData?.virtual_tour[0]);
  // Video id

  // Video
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
  }
  // Video

  // Property floorplan images lightbox
	const [photoIndex, setPhotoIndex] = useState(0);
	const [isOpen, setIsOpen] = useState(false);

	const openPropertyImage = (e, ind) => {
		e.preventDefault();
		setPhotoIndex(ind);
		setIsOpen(true);
	}

	const propertyImages = props?.propertyData?.floorplan
	var propertyLightImages = propertyImages && propertyImages.map(img => img.url);
	// Property floorplan images lightbox
  function gotohash(event) {
    var thishash = event;
    $('html, body').animate({
      scrollTop: $(thishash).offset().top-150
    }, 1000);
  }

  return (
    <div className="property-nav">
      <div className="shell">
        <div className="property-nav__inner">
          <div class="nav">
            {
              props?.propertyData?.description &&
              <div class="is-current nav-item"><a href="javascript:;"  onClick={() => gotohash("#description")} class="nav-link"><i class="icon-description"></i>Description</a></div>
            }

            {
              props?.propertyData?.virtual_tour?.length > 0 &&
              (
                videoid?.service === "youtube" ||  videoid?.service === "vimeo" ?
                <CustomVideo url={props?.propertyData?.virtual_tour[0]} vtype={videoid?.service} />
                :
                <div class="nav-item">
                  <a href="javascript:void(0)" onClick={handleShow} class="nav-link"><i class="icon-video"></i>Video</a>
                </div>
              )
              // <div class="nav-item"><a href="#video" onClick={scrollHandle} data-rr-ui-event-key="#video" class="nav-link"><i class="icon-video"></i>Video</a></div>
            }

            {
              props?.propertyData?.floorplan?.length > 0 &&
              <div class="nav-item"><a href="javascript:void(0)" onClick={(e) => openPropertyImage(e, 0)} class="nav-link"><i class="icon-floorplan"></i>Floorplan</a></div>
            }

            {
              props?.propertyData?.images?.length > 0 &&
              <div class="nav-item"><a href="javascript:;"  onClick={() => gotohash("#detailgallery")} class="nav-link"><i class="icon-gallery"></i>Gallery</a></div>
            }

            {
              props?.propertyData?.latitude && props?.propertyData?.longitude &&
              <div class="nav-item"><a  href="javascript:;"  onClick={() => gotohash("#property-details-map")}  class="nav-link"><i class="icon-map"></i>Map</a></div>
            }
            {props.propertyData.search_type ==="sales" &&
              <div class="nav-item"><a href="javascript:;"  onClick={() => gotohash("#calculators")}  class="nav-link"><i class="icon-calculators"></i>Calculators</a></div>
            }
          </div>

          {/* <Scrollspy
            items={['description', 'video', 'floorplan', 'gallery','map','calculators' ]}
            offset={-100}
            componentTag={Nav}
            currentClassName="is-current"
          >
            {links.map((link, ind) => (
              <Nav.Item key={ind}>
                <Nav.Link href={link.url} onClick={scrollHandle}>
                  <i className={`icon-${link.text.toLowerCase()}`} />
                  {link.text}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Scrollspy> */}
        </div>
      </div>

      {/* Floorplan image popup */}
      {isOpen && (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
          prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}
      {/* Floorplan image popup */}

      {/* Video modal */}
      <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
          dialogClassName="modal-fullscreen property-details-modal"
      >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body className="p-0">
              <iframe src={props?.propertyData && props?.propertyData?.virtual_tour && props?.propertyData?.virtual_tour.length > 0 && props?.propertyData?.virtual_tour[0]}></iframe>
          </Modal.Body>
      </Modal>
      {/* Video modal */}
    </div>
  )
}

export default PropertyNav
