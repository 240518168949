/**
 * External dependencies
 */
import React from "react"
import { Link } from "gatsby"
import { Col, Row } from "react-bootstrap"

/**
 * Internal dependencies
 */
import { isMobile } from "../../lib/media"
import Button from "../button/button"
import Share from "../share/share"
import PropertyPerson from "../property-person/property-person"
import IconCircle from "../icon-circle/icon-circle"
import Carousel from '../property-slider/carousel';

import "./styles/_index.scss"

const PropertyIntro = (props) => {
  return (
    <div className="property__intro" id="detailgallery">
      <div className="shell">
        <Row className="align-items-center property__intro-head">
          <Col>
            {/* <Link to={props.propertyData.search_type=="sales"?"/property/for-sale/in-sittingbourne/":"/property/to-rent/in-sittingbourne/"} className="property__intro-back cta-link" onClick={(e) => window.history.go(-1)}> */}
            <Link to={props.propertyData.search_type == "sales" ? "/property/for-sale/in-sittingbourne/" : "/property/to-rent/in-sittingbourne/"} className="property__intro-back cta-link">
              <i className="icon-arrow-left"></i>
              <span>Back to Search Listings</span>
            </Link>
          </Col>
          <Col
            xs="auto"
            className="d-block d-md-none property__intro-top-utils"
          >
            <Row>
              <Col>
                <a href={props?.propertyData?.brochure[0]?.srcUrl} target="_blank">
                  <i className="icon-brochure" />
                </a>
              </Col>
              <Col>
                <Share id={props.propertyData.id} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-md-center">
          <Col lg="7" className="carouse--wrap">
          {props.propertyData.status == "Sold Subject To Contract" &&
                    <span className="flagprop">Under Offer</span>
                }
                {props.propertyData.status == "Let Agreed" &&
                    <span className="flagprop">Let Agreed</span>
                }
                {props.propertyData.status == "Under Offer" &&
                    <span className="flagprop">Under Offer</span>
                }
            <Carousel
              bannerData={props?.propertyData} status={props.propertyData.status}
            />
          </Col>
          <Col>
            <div className="property__intro-content">
              {
                props?.propertyData?.price_qualifier &&
                <span className="pricewith--qualifier">{props?.propertyData?.price_qualifier}</span>
              }

              {
                props?.propertyData?.price &&
                <div className="property__price">£ {props?.propertyData?.price.toLocaleString()} {props?.propertyData?.search_type === "lettings" ? "PCM" : ""}</div>
              }

              {
                props?.propertyData?.display_address &&
                <h1>{props?.propertyData?.display_address}</h1>
              }

              {
                props?.propertyData?.title &&
                <h2>{props?.propertyData?.title}</h2>
              }

              <div className="responsive-holder">
                <Row className="gx-5">
                  {
                    props?.propertyData?.bedroom > 0 &&
                    props?.propertyData?.bedroom &&
                    <Col xs="auto">
                      <Row className="align-items-center">
                        <Col>
                          <IconCircle color="#2F477B">
                            <i className="icon-bed"></i>
                          </IconCircle>
                        </Col>
                        <Col>{props?.propertyData?.bedroom}</Col>
                      </Row>
                    </Col>
                  }

                  {
                    props?.propertyData?.bathroom > 0 &&
                    props?.propertyData?.bathroom &&
                    <Col xs="auto">
                      <Row className="align-items-center">
                        <Col>
                          <IconCircle color="#2F477B">
                            <i className="icon-bathroom"></i>
                          </IconCircle>
                        </Col>
                        <Col>{props?.propertyData?.bathroom}</Col>
                      </Row>
                    </Col>
                  }

                  {
                    props?.propertyData?.reception > 0 &&
                    props?.propertyData?.reception &&
                    <Col xs="auto">
                      <Row className="align-items-center">
                        <Col>
                          <IconCircle color="#2F477B">
                            <i className="icon-chair"></i>
                          </IconCircle>
                        </Col>
                        <Col>{props?.propertyData?.reception}</Col>
                      </Row>
                    </Col>
                  }
                </Row>
                <div className="property__intro-cta" id={props.propertyData.status == "Sold Subject To Contract" || props.propertyData.status == "Let Agreed"?"soldletcta": ""}>
                  {props.propertyData.status != "Sold Subject To Contract" && props.propertyData.status != "Let Agreed" &&
                    <Link className="btn btn--fill-lightblue " to={`/book-a-viewing?pid=${props.propertyData.id}`}>Enquire
                      <i className="icon-arrow-right"></i>
                    </Link>
                  }
                  
                    <a href={`tel:${'01795 293000'}`} className="btn btn--outline-white ctatel--prop ">
                      {isMobile ? "Call" : '01795 293000'} <i className="icon-phone"></i>
                    </a>
                  
                </div>
                <div className="property__intro-utils">
                  <Share id={props.propertyData.id} />
                  {
                    props?.propertyData?.brochure?.length > 0 && props?.propertyData?.brochure[0]?.srcUrl &&
                    <a href={props?.propertyData?.brochure[0]?.srcUrl} target="_blank" className="brochure">
                      <i className="icon-brochure"></i> Brochure
                    </a>
                  }
                </div>
              </div>
              <PropertyPerson user={'LambornHill'} email={'sittingbourne@lambornhill.com'} phone={'01795 293000'} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PropertyIntro
