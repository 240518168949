/**
 * External dependencies
 */
import React, { useRef, useState, useEffect } from "react"
import Carousel from "react-elastic-carousel"
import axios from "axios"

/**
 * Internal dependencies
 */
import { isMobile } from "../../lib/media"
import PropertyBox from "../property-box/property-box"

import "./styles/_index.scss"

const PropertyRelated = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const carousel = useRef()
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 689, itemsToShow: 2 },
    { width: 900, itemsToShow: 3 },
    { width: 1100, itemsToShow: 4 },
  ]

  const title = isMobile
    ? "Other Properties"
    : "Other properties that may interest you"

  //
  const [propItems, setPropItems] = useState([])

  const getitems = async url => {
      try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        }})
      // 
      // console.log("PropertySimilar", data)
      setPropItems(data)
      } catch (error) {
      // console.error(error)
      }
  }

  useEffect(() => {
      // 
      let url =
      process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/Property-details?pid=" +
      props?.propertyData?.id

      getitems(url)
  }, []);
  //

  return (
    propItems.length > 0 &&
    <div className="property-related-wrapper">
      <div className="property-related" data-aos="fade-up">
        <div className="shell">
          <div className="property-related__inner">
            <div className="property-related__top">
              <h3>{title}</h3>
              <div className="property-related__arrows">
                <button onClick={() => carousel.current.slidePrev()}>
                  <i className="icon-arrow-left" />
                </button>
                <button onClick={() => carousel.current.slideNext()}>
                  <i className="icon-arrow-right" />
                </button>
              </div>
            </div>

            <Carousel
              breakPoints={breakPoints}
              showArrows={false}
              ref={carousel}
              showEmptySlots
            >
              {
                propItems.map((item, index) => {
                  return (
                    <PropertyBox
                      data={item}
                      key={''}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                    />
                  )
                })
              }
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyRelated
