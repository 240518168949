/**
 * External dependencies
 */
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import HTMLReactParser from 'html-react-parser';

/**
 * Internal dependencies
 */
import BtnLink from "../btn-link/btn-link"
import IconCircle from "../icon-circle/icon-circle"
import usePropertyDetails from "./use-property-details"
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import { AreaGuideDetail } from '../../queries/common_use_query'
import parse from 'html-react-parser';
import {PropertValuationURL} from "../utils"


import "./styles/_index.scss"

const PropertyDetails = (props) => {
  const {loading:loading, error:error, data:data} = AreaGuideDetail(props.postcode.toLowerCase());
  const items = usePropertyDetails()
  const videoImage = items[0].node.childrenImageSharp[0].fluid.src
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {
        props?.propertyData &&
        <div className="property-details-wrapper">
          <div
            className="property-details"
            data-aos="fade-up"
            data-item="description"
          >
            <div className="shell">
              <Row className="justify-content-md-space-between">
                <Col lg="7">
                  <div id="description" className="property-details__content">
                    {
                      props?.propertyData?.accomadation_summary &&
                      <>
                        <h4>Property at a glance</h4>
                        <ul>
                          {
                            props?.propertyData?.accomadation_summary.map((items) => {
                              return (
                                <li>{items.replace("&#163;","£")}</li>
                              )
                            })
                          }
                        </ul>
                        <hr />
                      </>
                    }
                    
                    {
                      props?.propertyData?.description &&
                      <>
                        <h4>Property Description</h4>
                        <p>{HTMLReactParser(props?.propertyData?.description)}</p>
                      </>
                    }
                  </div>
                  {data && data.areaGuides.map((item,index) => {
            let processedImages = JSON.stringify({});
            if (item?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = item.imagetransforms.Tile_Image_Transforms;
            }
            var str = item.Postcode_Mapping;
            var search = props.postcode.toLowerCase();
            var arr = str.split(", ");

            if (arr.indexOf(search) !== -1) {
              
              return<>


                  <div className="property-details__local" data-item="video">


                    <h4>The Local Area</h4>
                    <Row>
                      <Col md="8">
                       
                        {parse(item.Above_Content.slice(0, 260)+'...')}

                        <BtnLink href={`/about/areas-we-cover/${item.URL}/`}>
                          {item.Name} Area Guide{" "}
                          <i className="icon-arrow-right"></i>
                        </BtnLink>
                      </Col>
                      <Col md="4 ">
                        <div className="property-video" id="video">
                          <div className="property-video__inner">
                            <VideoPlaceholder
                              isOpen={isOpen}
                              setIsOpen={setIsOpen}
                              image={item.Tile_Image && item.Tile_Image.url}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  </>
            }
            })}

                </Col>
                <Col
                  lg={{
                    span: 4,
                    offset: 1,
                  }}
                >
                  <div className="property-details__sticky">
                    <h4>Get in touch</h4>
                    <ul>
                      <li>
                        <Row className="align-items-md-center">
                          <Col md="auto">
                            <IconCircle color="#EAF2F4">
                              <i className="icon-pin"></i>
                            </IconCircle>
                          </Col>
                          <Col>{'LambornHill, 19-21 West Street, Sittingbourne, Kent, ME10 1AJ'}</Col>
                        </Row>
                      </li>
                      <li>
                        <Row className="align-items-md-center">
                          <Col md="auto">
                            <IconCircle color="#EAF2F4">
                              <i className="icon-phone-bold"></i>
                            </IconCircle>
                          </Col>
                          <Col>
                            <a href={`tel: +${'01795 293000'}`}>{'01795 293000'}</a>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row className="align-items-md-center">
                          <Col md="auto">
                            <IconCircle color="#EAF2F4">
                              <i className="icon-envelope"></i>
                            </IconCircle>
                          </Col>
                          <Col>
                            <Link to="/contact-estate-agents-in-sittingbourne/">
                              sittingbourne@lambornhill.com
                            </Link>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row className="align-items-md-center">
                          <Col md="auto">
                            <IconCircle color="#EAF2F4">
                              <i className="icon-price"></i>
                            </IconCircle>
                          </Col>
                          <Col>
                            Do you need to sell your home?{" "}
                            <div><strong><Link to={PropertValuationURL}>Book a valuation</Link></strong> with one of our team.</div>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default PropertyDetails
