/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types"
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import { filterNumber, numberFormat } from "./util"
import {
    calculate,
    countries,
    // propertyTypes,
    // buyerTypes,
} from "@starberry/react-calculator-stampduty"
import $ from "jquery";
import Select from "react-dropdown-select";

// we could user uk-ireland-stampduty-calculator's propertyTypes & buyerTypes
// but key val par diff we could get valus using Object.values but it is not
// supported in IE - https://caniuse.com/#feat=object-values
// so defined our our custom key pairs


const propertyTypes = [
    { value: 'residential', label: 'Residential' }
]

const buyerTypes = [
    { value: 'first', label: 'First time Buyer' },
    { value: 'investor', label: 'Second home' }
]



// use project specific import here

// DO NOT DO ANY CHNAGES - START
const StampdutyCalc = (props) => {
    const currency = props.currency

    const [result, setResult] = useState(false)

    const [propertyType, setPropertyType] = useState(props.propertyType)
    const [propertyValue, setPropertyValue] = useState(
        numberFormat(filterNumber(props.propertyValue))
    )
    const [buyer, setBuyer] = useState(props.buyerType)

    const formatPrice = str => {
        return currency + str.toLocaleString("en-US")
    }
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const GTMtracker = (event) => {  
       window.dataLayer = window.dataLayer || [];
       window.dataLayer.push({
         'event': 'Stamp Duty Calculator',
         'formType': url,
         'formId': 'Stamp Duty Calculator',
         'formName': 'Stamp Duty Calculator',
         'formLabel': 'Stamp Duty Calculator'
       });
     }
    const doCalculate = (purchase_price_price) => {
        const results = calculate(
            filterNumber(purchase_price_price ? purchase_price_price : propertyValue),
            propertyType,
            countries.ENGLAND,
            buyer
        )
        // effective stamp duty rates
        // Formula => ((tax / price) * 100).
        // Ex: (213750/2500000) * 100 = 8.55%.
        let effectiveRate = (results.tax / filterNumber(propertyValue)) * 100
        effectiveRate = new Intl.NumberFormat("en-US", {}).format(effectiveRate)
        let summaryBands = []
        results.summaryBands.map(result => {
            summaryBands.push(
                result.adjustedRate +
                "% between " +
                formatPrice(result.start) +
                " and " +
                formatPrice(result.end)
            )
        })
        const result = {
            effectiveRate: effectiveRate + "%",
            summaryBands: summaryBands,
            tax: formatPrice(results.tax),
        }
        setResult(result)
    }

    const handleSubmit = event => {
        event.preventDefault()
        event.stopPropagation()
        doCalculate()
    }

    const handleDeposit = event => {
        var purchase_price_price = numberFormat(filterNumber(event.target.value));
        doCalculate(purchase_price_price);
        GTMtracker();
        setPropertyValue(purchase_price_price)
    }

    const handlePropertyType = event => {
        //console.log('aaaaaaaaaa', event)
        setPropertyType(event[0].value)
        doCalculate()
        GTMtracker();
    }

    const handleBuyer = event => {
        setBuyer(event[0].value)
        doCalculate()
        setTimeout(function () {
            $('.btn-stamp-calculate').click();
        }, 300);
        GTMtracker();
    }

    // DO NOT DO ANY CHNAGES - END
    //console.log('propertyValue',propertyValue)
    useEffect(() => {
        doCalculate()
        $('.btn-stamp-calculate').click();
    }, [propertyValue]);
    return (
        <div className="form mortgate-form ">
            <div className='calc-st-wrap'>
                <div className="form-property-calculator">
                    <Form
                        name="MortgageCalc"
                        noValidate
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <div className="form-stamp calculator-form">
                            <Row className="gx-5 flex-wrap">

                                <input type="hidden" name="bot-field" />

                                <Col>

                                    <Form.Group controlId="property_type">
                                        <Form.Label>Property Type</Form.Label>

                                        <Select className="departments form-control" name="property_type" placeholder={`Residential`} value={`residential`} defaultValue={`residential`} options={propertyTypes} onChange={handlePropertyType} searchable={false} />
                                    </Form.Group>
                                </Col>

                                <Col className="col-price">

                                    <Form.Group controlId="deposit">
                                        <Form.Label>Purchase Price</Form.Label>
                                        <div className="form-addon-left form-border">
                                            <Form.Control
                                                className="form-control"
                                                required
                                                type="text"
                                                name="deposit"
                                                value={propertyValue}
                                                placeholder="Purchase Price"
                                                onChange={handleDeposit}
                                                maxLength={13}
                                            />
                                            <span></span>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>

                                    <Form.Group controlId="buyer_type">
                                        <Form.Label>Buyer Type</Form.Label>

                                        <Select searchable={false} className="departments form-control" name="buyer" placeholder={`First Time Buyer`} value={`first`} defaultValue={`first`} options={buyerTypes} onChange={handleBuyer} />
                                    </Form.Group>
                                </Col>

                                <Button type="submit" className="d-none btn btn-pink btn-stamp-calculate">
                                    Calculate
                                </Button>
                                <Col>
                                    <Form.Group controlId="property-price">
                                        <Form.Label>Stamp Duty</Form.Label>
                                        <div className="calculator-form__total">{result.tax}</div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="form-col">
                            <Form.Group className="stamp-duty-field">
                                {/* <Form.Label>Stamp Duty:</Form.Label>
                                <span className="mrp-amount">{result.tax}</span> */}
                                {result && (
                                    <p className="stampdutytext">
                                        <div className="stamp-head">You have to pay stamp duty:</div>
                                        <div className="summary-stamp">
                                        {result.summaryBands.map((sm, i) => {
                                            return <span key={i}>{sm}</span>
                                        })}
                                        </div>
                                        <div className="stamp-eff">
                                            Your effective stamp duty rate is {result.effectiveRate}
                                        </div>
                                    </p>
                                )}
                            </Form.Group>
                        </div>
                        </div>


                    </Form>
                </div>
            </div>
            {result && (
                <div className="alert-success d-none">
                    <div>
                       
                        <p>
                            <div className="stamp-head">You have to pay stamp duty:</div>
                            {result.summaryBands.map((sm, i) => {
                                return <span key={i}>{sm}</span>
                            })}
                            <div className="stamp-eff">
                                Your effective stamp duty rate is {result.effectiveRate}
                            </div>
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

StampdutyCalc.defaultProps = {
    propertyType: `residential`,
    propertyValue: `0`,
    buyerType: `first`,
    currency: process.env.CURRENCY ? process.env.CURRENCY : "£",
}

StampdutyCalc.propTypes = {
    propertyType: PropTypes.string,
    propertyValue: PropTypes.any,
    buyerType: PropTypes.string,
    currency: PropTypes.string,
}

export default StampdutyCalc

