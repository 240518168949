/**
 * External Dependencies
 */
import React, { useEffect, useState, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

/**
 * Internal Dependencies
 */
import './styles/_index.scss';
import Thumb from './thumb';
import ImageTransform from "../common/ggfx-client/module/components/image-transform"
import NoImage from "../../images/no-image.png"

const Carousel = (props) => {
    //console.log('aaaaaaaa', props?.bannerData?.images.length)
    const SLIDE_COUNT = props?.bannerData?.images.length + 4;
    const slides = Array.from(Array(SLIDE_COUNT).keys());
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [emblaRef, embla] = useEmblaCarousel({ loop: true })
    const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
        containScroll: "initial",
        selectedClass: "",
        dragFree: true,
        inViewThreshold: 0.6
    });

    const onThumbClick = useCallback(
        (index) => {
            if (!embla || !emblaThumbs) return;
            if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
            
        },
        [embla, emblaThumbs]
    );

    const onPointerUp = useCallback(
        () => {
            if (!embla || !emblaThumbs) return;
            let firstImageInViewIndex = emblaThumbs.slidesInView()[0];
            setSelectedIndex(firstImageInViewIndex);
            embla.scrollTo(firstImageInViewIndex);
            
        },
        [embla, emblaThumbs]
    );

    const onSelect = useCallback(() => {
        if (!embla || !emblaThumbs) return;
        setSelectedIndex(embla.selectedScrollSnap());
        emblaThumbs.scrollTo(embla.selectedScrollSnap());
    }, [embla, emblaThumbs, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        embla.on("select", onSelect);
        emblaThumbs.on('pointerUp', onPointerUp);
    }, [embla, onSelect, onPointerUp]);

    const scrollPrev = useCallback(() => {
        if (embla) embla.scrollPrev();
    }, [embla])

    const scrollNext = useCallback(() => {
        //console.log('aaaaa', embla)
        if (embla) embla.scrollNext();
    }, [embla])

    let imagesLeft = SLIDE_COUNT - 4 - selectedIndex;
    // if(imagesLeft == 0) {
    //     imagesLeft = SLIDE_COUNT - 5
    // }
    let TotImage = SLIDE_COUNT - 4;
    return (
        props?.bannerData &&
        <>
            <div className="carousel" ref={emblaRef} >
                
                <div className="carousel__container">
                    {
                        props?.bannerData?.images.length > 0 ?
                        props?.bannerData?.images.map((bannerImg, index) => {
                            // ggfx images
                            let processedImages = JSON.stringify({});
                            if (props.bannerData?.imagetransforms?.images_Transforms) {
                                processedImages = props.bannerData.imagetransforms.images_Transforms;
                            }
                            // ggfx images
                            //console.log('aaaaaa 1', bannerImg)
                            return (
                                <div className="carousel__slide" key={index}>
                                    
                                    <div className="carousel__slide__inner">
                                        <div className="">
                                            <picture>
                                                <ImageTransform 
                                                    imagesources={bannerImg.url} 
                                                    renderer="srcSet" 
                                                    imagename="property.images.detail"
                                                    attr={{ alt: props?.bannerData?.display_address+ ' -  LambornHill' }} 
                                                    imagetransformresult={processedImages} 
                                                    id={props.bannerData.id} 
                                                    testparam={true} 
                                                />
                                            </picture>
                                        </div>
                                        {/* <StaticImage src="./images/house.png" style={{ width: "100%", height: "100%" }} alt="House" /> */}
                                    </div>
                                </div>
                            )
                        })
                        :
                        <img src={NoImage} alt="img" />
                    }

                </div>
                <div className="carousel__prev">
                    <div className={classNames(
                        `arrow-holder left`, 
                        { "is-disabled": selectedIndex === 0 })} onClick={scrollPrev}
                    >
                        <i className="icon-arrow-left"></i>
                    </div>
                </div>
                <div className="carousel__next">
                    <div className={classNames(
                        `arrow-holder right`, 
                        { "is-disabled": selectedIndex === SLIDE_COUNT - 1 })} onClick={scrollPrev} onClick={scrollNext}
                    >
                        <i className="icon-arrow-right"></i>
                    </div>
                </div>
                <div className="carousel__progress">
                    {`${selectedIndex + 1}/${TotImage}`}
                </div>
            </div>

            <div className="carousel carousel--thumb">
                <div className="carousel__viewport" ref={thumbViewportRef}>
                    <div className="carousel__container carousel__container--thumb">
                        {props?.bannerData?.images.map((thumbImg, index) => (
                            <Thumb
                                onClick={() => onThumbClick(index)}
                                selected={index === selectedIndex}
                                key={index}
                                thumbImage={thumbImg?.url}
                            />
                        ))}
                    </div>
                </div>
                <div className="carousel--thumb__progress" onClick={scrollNext}>
                    <span>+{imagesLeft > 0 ? imagesLeft : 0}</span>
                </div>
            </div>
        </>
    )
}

export default Carousel;