import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap"
import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';
// import {Link}  from "gatsby"
import ReactPlayer from 'react-player'
// import { useLocation } from "@reach/router"
import $ from "jquery"
import {
    isIOS,
    isMobile
} from "react-device-detect";
import "./custom-video.scss"


const CustomVideo = (props) => {
    //const [isOpen, setOpen] = useState(false);
    //console.log('showHTMLVideo',props.vtype, props.url)
     const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }

//   const [isPlay, setPlay] = useState(false);
  const vidRef = useRef(null);
  const [show, setShow] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const [controls, setVideoControls] = useState(false);
  
  
  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const pausevideo = (ref) => {
    setPlayvideo(false)
    setVideoControls(false)
  
  }
  const playvideo = (ref) => {
    setPlayvideo(true)
    setVideoControls(false)
    setTimeout(function(){ $(".modal-bg-black").hide() }, 1700);
  }
  const showControls = () => {
    setVideoControls(true)
  }
    
    return(
        <>
        
            {/* <strong className="video-btn"  onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>
                <i className="icon-video"></i>
            </strong> */}

            <div class="nav-item">
                <a href="javascript:void(0)" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }} class="nav-link"><i class="icon-video"></i>Video</a>
            </div>
            {showHTMLVideo  && props.vtype == "vimeo" ?

<Modal
show={show}
onHide={() => setShow(false)}
dialogClassName="modal-video"
aria-labelledby="example-custom-modal-styling-title"
backdropClassName="video-backdrop"
className="full-video-popup"
>
<Modal.Header closeButton>
</Modal.Header>
    <Modal.Body>
    {isMobile &&
    <div className="modal-bg-black">
    </div>
    }
    <ReactPlayer url={props.url} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay ref={vidRef} onPlay={trackerVideo(props.title)} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100vh'  previewTabIndex={0} light={false} />
    </Modal.Body>
</Modal>

            :
                <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                className="full-video-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>
                    {isMobile &&
                    <div className="modal-bg-black">
                    </div>
                    }
                    <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay ref={vidRef} onPlay={trackerVideo(props.title)} url={props.url} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100vh'  previewTabIndex={0} light={false}/>
                    </Modal.Body>
                </Modal>

            }

        </>
    )
}

export default CustomVideo