import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Thumb = ({ selected, onClick, thumbImage }) => {
    //console.log('aaaaaaaaaaa', thumbImage)
    return (
        <div className={`carousel__slide carousel__slide--thumb ${selected ? "is-selected" : ""}`}>
            <button
                onClick={onClick}
                className="carousel__slide__inner carousel__slide__inner--thumb"
                type="button"
            >
                <div className="property-thumb-img">
                    <picture>
                        <img src={thumbImage} className="" />
                    </picture>
                </div>
                {/* <StaticImage 
                    style={{width: "100%", height: "100%"}} 
                    className="carousel__slide__thumbnail" 
                    src={thumbImage}
                    alt="House" 
                /> */}
            </button>
        </div>
    )
}

export default Thumb;