/**
 * External dependencies
 */
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Row, Tab, Tabs } from "react-bootstrap"

/**
 * Internal dependencies
 */
import CalculatorForm from "../../blocks/calculator-form/calculator-form"
import MortageCalc from "../calculators/mortgagecalc/index"
import StampDutyCalculator from "../calculators/stampdutycalc/property-stampduty"
import {ContactURL} from "../utils"

import BtnLink from "../btn-link/btn-link"

import "./styles/_index.scss"

const PropertyCalculator = (props) => (
  <div
    className="property-calculator mb-0"
    data-aos="fade-up"
    data-item="calculators"
    id="calculators"
  >
    <div className="shell">
      <div className="property-calculator__inner">
        <h4>How much would my mortgage or stamp duty be for this property?</h4>
        <Tabs defaultActiveKey={props.search_type==="sales"?"mortgage":"stamp-duty"}>
          <Tab eventKey="mortgage" title="Mortgage Calculator">
            <MortageCalc propertyPrice={props.propertyValue} />
            {/* <CalculatorForm /> */}
          </Tab>
          <Tab eventKey="stamp-duty" title="Stamp Duty Calculator">
            <StampDutyCalculator propertyValue={props.propertyValue}/>
            {/* <CalculatorForm /> */}
          </Tab>
        </Tabs>
        <div className="property-calculator__user">
          <Row className="justify-content-md-start align-items-md-center">
            {/* <Col xs="2" md="auto">
              <div className="property-calculator__user-avatar">
                <StaticImage
                  src="./images/avatar.png"
                  width={40}
                  height={40}
                  alt="avatar"
                />
              </div>
            </Col> */}
            <Col xs="10" md="auto">
              <p>Need help or ready to get started with your mortgage?</p>
            </Col>
            <Col>
              <BtnLink href={ContactURL}>
                Speak with our broker
                <i className="icon-arrow-right"></i>
              </BtnLink>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </div>
)

export default PropertyCalculator
