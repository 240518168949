import React, { useState, useEffect, PropTypes } from "react"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import PropertyIntro from "../components/property-intro/property-intro"
import PropertyDetails from "../components/property-details/property-details"
import PropertyCalculator from "../components/property-calculator/property-calculator"
import PropertyRelated from "../components/property-related/property-related"
import PropertyCols from "../components/property-cols/property-cols"
import PropertyFind from "../components/property-find/property-find"
import PropertyPrepare from "../components/property-prepare/property-prepare"
import PropertyVideos from "../components/property-videos/property-videos"
import PropertyReviews from "../components/property-reviews/property-reviews"
import PropertyNav from "../components/property-nav/property-nav"
import LocRatingMap from "../components/map/loc-rating-map-details"
import BrandLogo from "../images/icon.png";
import Property404 from "../pages/Property404"
const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{id: $id, publish:true}) {
        id
        crm_id
        status
        department
        search_type
        publish
        building
        price
        price_qualifier
        bedroom
        bathroom
        reception
        slug
        display_address
        crm_negotiator_id
        title
        latitude
        longitude
        description
        images
        imagetransforms
        accomadation_summary
        virtual_tour
        floorplan
        brochure
        address
    }
  }
`;

const PropertyDetailsTemplate = (props) => {
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      }
    // 
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id }
    });

    // Loading logo 
    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" class="new-loader">
                    <img className="logo-white" src={BrandLogo} className="loader-logo" alt="logo"/>
                </div>
            </div>
        </section>
    );
    // Loading logo
    // 
    var searchptype = property_details && property_details.properties && property_details.properties.length > 0 && property_details.properties[0] && property_details.properties[0].search_type.charAt(0).toUpperCase() + property_details.properties[0].search_type.slice(1);
    return (
        <Layout popularsearch={searchptype}>
            {
                property_details && property_details.properties && property_details.properties.length > 0 ? property_details.properties.map((data, i) => {

                    // Get Map Details
                    let mapItems = [];
                    let mapItem = {};
                    mapItem['lat']  = data.latitude      
                    mapItem['lng']  = data.longitude
                    mapItem['id']  = data.id
                    const mapMarkerImageShowFlag = 0;
                    mapItems.push( mapItem );
                    // Get Map Details
                    var searchaction = data.search_type === "sales"?"for sale":"to let"
                    var metatile = +data.bedroom+" bedroom "+capitalize(data.building.replace(/-/g, ' '))+" "+searchaction+" in "+data.display_address+"";
                    var metadesc = "Find the details of "+data.bedroom+" bedroom "+data.building.replace(/-/g, ' ')+" "+searchaction+" in "+data.display_address+" with LambornHill at £"+data.price.toLocaleString()+". Request a viewing to get assistance in knowing more about this "+capitalize(data.building.replace(/-/g, ' '))+"."
                    return (
                        <React.Fragment>
                            <Seo image={data.images && data.images[0].url} title={metatile} description={metadesc} />

                            <div className="property">
                                <PropertyIntro propertyData={data} />
                                <PropertyNav propertyData={data}  />
                                <PropertyDetails propertyData={data} postcode={data && data.address && data.address.postcode && data.address.postcode.split(" ")[0]} />
                                {data.search_type === "sales" &&
                                    <PropertyCalculator search_type={data.search_type} propertyValue={data.price}/>
                                }
                                <div className="property-details-landing-map" id="property-details-map" data-item="property-details-map">
                                    <LocRatingMap data={mapItems} />
                                </div>
                                <PropertyRelated propertyData={data} />
                                {/* <PropertyCols /> */}
                            </div>
                        </React.Fragment>
                    )
                }) : <Property404 />
            }
        </Layout>
    )
}

export default PropertyDetailsTemplate