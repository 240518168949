/**
 * External dependencies
 */
import React from "react"
import classNames from "classnames"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { Col, Form, Row } from "react-bootstrap"

/**
 * Internal dependencies
 */

import "./styles/_index.scss"

const schema = yup.object().shape({
  price: yup.number().required(),
  deposit: yup.number().required(),
  duration: yup.number().required(),
  interest: yup.number().required(),
})

const CalculatorForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  })

  const onFormSubmit = () => "submitted";
  return (
    <div className="calculator-form">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Row className="gx-5 flex-wrap">
          <Col className="col-price">
            <Form.Group controlId="property-price">
              <Form.Label>Property Price</Form.Label>
              <Controller
                className="price"
                control={control}
                name="price"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Form.Control
                    type="text"
                    onChange={onChange}
                    className={classNames({ "has-error": error })}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col className="col-price">
            <Form.Group controlId="property-deposit">
              <Form.Label>Deposit:</Form.Label>
              <Controller
                control={control}
                name="deposit"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Form.Control
                    type="text"
                    onChange={onChange}
                    className={classNames({ "has-error": error })}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="price">
              <Form.Label>Duration (Years):</Form.Label>
              <Controller
                control={control}
                name="duration"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Form.Control
                    type="text"
                    onChange={onChange}
                    className={classNames({ "has-error": error })}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="property-rate">
              <Form.Label>Interest Rate (%):</Form.Label>
              <Controller
                control={control}
                name="interest"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Form.Control
                    type="text"
                    onChange={onChange}
                    className={classNames({ "has-error": error })}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="property-price">
              <Form.Label>Monthly Repayment</Form.Label>
              <div className="calculator-form__total">£1,558</div>
            </Form.Group>
          </Col>
        </Row>
        <button type="submit" style={{ display: "none" }}>
          submit
        </button>
      </form>
    </div>
  )
}

export default CalculatorForm
